/*
------------------------------------------------------------
    Light
------------------------------------------------------------
*/

/* (GothamNarrow-Thin)              weight: 100         style: normal */
@font-face {
  font-family: 'GothamNarrow';
  src: url('./GothamNarrow-Thin.otf');
  src: local('GothamNarrow-Thin'), url('./GothamNarrow-Thin.otf') format('opentype'),
    url('./GothamNarrow-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: block;
}

/* (GothamNarrow-Thin Italic)       weight: 100,        style: italic */
@font-face {
  font-family: 'GothamNarrow';
  src: url('./GothamNarrow-ThinItalic.otf');
  src: local('GothamNarrow-ThinItalic'), url('./GothamNarrow-ThinItalic.otf') format('opentype'),
    url('./GothamNarrow-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: block;
}

/* (GothamNarrow-Light)             weight: 300,        style: normal */
@font-face {
  font-family: 'GothamNarrow';
  src: url('./GothamNarrow-Light.otf');
  src: local('GothamNarrow-Light'), url('./GothamNarrow-Light.otf') format('opentype'),
    url('./GothamNarrow-Thin.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: block;
}

/* (GothamNarrow-Light Italic)      weight: 300         style: italic */
@font-face {
  font-family: 'GothamNarrow';
  src: url('./GothamNarrow-LightItalic.otf');
  src: local('GothamNarrow-LightItalic'), url('./GothamNarrow-LightItalic.otf') format('opentype'),
    url('./GothamNarrow-Thin.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: block;
}

/*
------------------------------------------------------------
    Normal
------------------------------------------------------------
*/

/* (GothamNarrow-Book)              weight: normal,   style: normal */
@font-face {
  font-family: 'GothamNarrow';
  src: url('./GothamNarrow-Book.otf');
  src: local('GothamNarrow-Book'), url('./GothamNarrow-Book.otf') format('opentype'),
    url('./GothamNarrow-Thin.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

/* (GothamNarrow-Book)              weight: normal,   style: italic */
@font-face {
  font-family: 'GothamNarrow';
  src: url('./GothamNarrow-BookItalic.otf');
  src: local('GothamNarrow-BookItalic'), url('./GothamNarrow-BookItalic.otf') format('opentype'),
    url('./GothamNarrow-Thin.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: block;
}

/*
------------------------------------------------------------
    Medium
------------------------------------------------------------
*/

/* (GothamNarrow-Medium)              weight: 500,   style: normal */
@font-face {
  font-family: 'GothamNarrow';
  src: url('./GothamNarrow-Medium.otf');
  src: local('GothamNarrow-Medium'), url('./GothamNarrow-Medium.otf') format('opentype'),
    url('./GothamNarrow-Thin.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: block;
}

/* (GothamNarrow-Medium)              weight: normal,   style: italic */
@font-face {
  font-family: 'GothamNarrow';
  src: url('./GothamNarrow-MediumItalic.otf');
  src: local('GothamNarrow-MediumItalic'), url('./GothamNarrow-MediumItalic.otf') format('opentype'),
    url('./GothamNarrow-Thin.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: block;
}

/*
------------------------------------------------------------
    Bold
------------------------------------------------------------
*/

/* (GothamNarrow-Bold)              weight: bold      style: normal */
@font-face {
  font-family: 'GothamNarrow';
  src: url('./GothamNarrow-Bold.otf');
  src: local('GothamNarrow-Bold'), url('./GothamNarrow-Bold.otf') format('opentype'),
    url('./GothamNarrow-Thin.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: block;
}

/* (GothamNarrow-Bold Italic)       weight: bold      style: italic */
@font-face {
  font-family: 'GothamNarrow';
  src: url('./GothamNarrow-BoldItalic.otf');
  src: local('GothamNarrow-BoldItalic'), url('./GothamNarrow-BoldItalic.otf') format('opentype'),
    url('./GothamNarrow-Thin.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: block;
}

/*
------------------------------------------------------------
    Bolder
------------------------------------------------------------
*/

/* (GothamNarrow-Black)              weight: black      style: normal */
@font-face {
  font-family: 'GothamNarrow';
  src: url('./GothamNarrow-Black.otf');
  src: local('GothamNarrow-Black'), url('./GothamNarrow-Black.otf') format('opentype'),
    url('./GothamNarrow-Thin.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: block;
}

/* (GothamNarrow-Black Italic)       weight: black      style: italic */
@font-face {
  font-family: 'GothamNarrow';
  src: url('./GothamNarrow-BoldItalic.otf');
  src: local('GothamNarrow-BoldItalic'), url('./GothamNarrow-BoldItalic.otf') format('opentype'),
    url('./GothamNarrow-Thin.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: block;
}
