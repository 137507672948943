/*
------------------------------------------------------------
    Light
------------------------------------------------------------
*/

/* (Gotham-Thin)              weight: 100         style: normal */
@font-face {
  font-family: 'Gotham';
  src: url('../webfonts/Gotham-Thin.eot');
  src: local('Gotham-Thin'), url('../webfonts/Gotham-Thin.eot?#iefix') format('embedded-opentype'),
    url('../webfonts/Gotham-Thin.woff2') format('woff2'),
    url('../webfonts/Gotham-Thin.woff') format('woff'),
    url('../webfonts/Gotham-Thin.ttf') format('truetype'),
    url('../webfonts/Gotham-Thin.svg#Gotham-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: block;
}

/* (Gotham-Thin Italic)       weight: 100,        style: italic */
@font-face {
  font-family: 'Gotham';
  src: url('../webfonts/Gotham-ThinItalic.eot');
  src: local('Gotham-ThinItalic'),
    url('../webfonts/Gotham-ThinItalic.eot?#iefix') format('embedded-opentype'),
    url('../webfonts/Gotham-ThinItalic.woff2') format('woff2'),
    url('../webfonts/Gotham-ThinItalic.woff') format('woff'),
    url('../webfonts/Gotham-ThinItalic.ttf') format('truetype'),
    url('../webfonts/Gotham-ThinItalic.svg#Gotham-ThinItalic') format('svg');
  font-weight: 100;
  font-style: italic;
  font-display: block;
}

/* (Gotham-Light)             weight: 300,        style: normal */
@font-face {
  font-family: 'Gotham';
  src: url('../webfonts/Gotham-Light.eot');
  src: local('Gotham-Light'), url('../webfonts/Gotham-Light.eot?#iefix') format('embedded-opentype'),
    url('../webfonts/Gotham-Light.woff2') format('woff2'),
    url('../webfonts/Gotham-Light.woff') format('woff'),
    url('../webfonts/Gotham-Light.ttf') format('truetype'),
    url('../webfonts/Gotham-Light.svg#Gotham-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: block;
}

/* (Gotham-Light Italic)      weight: 300         style: italic */
@font-face {
  font-family: 'Gotham';
  src: url('../webfonts/Gotham-LightItalic.eot');
  src: local('Gotham-LightItalic'),
    url('../webfonts/Gotham-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('../webfonts/Gotham-LightItalic.woff2') format('woff2'),
    url('../webfonts/Gotham-LightItalic.woff') format('woff'),
    url('../webfonts/Gotham-LightItalic.ttf') format('truetype'),
    url('../webfonts/Gotham-LightItalic.svg#Gotham-LightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
  font-display: block;
}

/*
------------------------------------------------------------
    Normal
------------------------------------------------------------
*/

/* (Gotham-Book)              weight: normal,   style: normal */
@font-face {
  font-family: 'Gotham';
  src: url('../webfonts/Gotham-Book.eot');
  src: local('Gotham-Book'), url('../webfonts/Gotham-Book.eot?#iefix') format('embedded-opentype'),
    url('../webfonts/Gotham-Book.woff2') format('woff2'),
    url('../webfonts/Gotham-Book.woff') format('woff'),
    url('../webfonts/Gotham-Book.ttf') format('truetype'),
    url('../webfonts/Gotham-Book.svg#Gotham-Book') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

/* (Gotham-Book)              weight: normal,   style: italic */
@font-face {
  font-family: 'Gotham';
  src: url('../webfonts/Gotham-BookItalic.eot');
  src: local('Gotham-BookItalic'),
    url('../webfonts/Gotham-BookItalic.eot?#iefix') format('embedded-opentype'),
    url('../webfonts/Gotham-BookItalic.woff2') format('woff2'),
    url('../webfonts/Gotham-BookItalic.woff') format('woff'),
    url('../webfonts/Gotham-BookItalic.ttf') format('truetype'),
    url('../webfonts/Gotham-BookItalic.svg#Gotham-BookItalic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: block;
}

/*
------------------------------------------------------------
    Bold
------------------------------------------------------------
*/

/* (Gotham-Bold)              weight: bold      style: normal */
@font-face {
  font-family: 'Gotham';
  src: url('../webfonts/Gotham-Bold.eot');
  src: local('Gotham-Bold'), url('../webfonts/Gotham-Bold.eot?#iefix') format('embedded-opentype'),
    url('../webfonts/Gotham-Bold.woff2') format('woff2'),
    url('../webfonts/Gotham-Bold.woff') format('woff'),
    url('../webfonts/Gotham-Bold.ttf') format('truetype'),
    url('../webfonts/Gotham-Bold.svg#Gotham-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: block;
}

/* (Gotham-Bold Italic)       weight: bold      style: italic */
@font-face {
  font-family: 'Gotham';
  src: url('../webfonts/Gotham-BoldItalic.eot');
  src: local('Gotham-BoldItalic'),
    url('../webfonts/Gotham-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../webfonts/Gotham-BoldItalic.woff2') format('woff2'),
    url('../webfonts/Gotham-BoldItalic.woff') format('woff'),
    url('../webfonts/Gotham-BoldItalic.ttf') format('truetype'),
    url('../webfonts/Gotham-BoldItalic.svg#Gotham-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: block;
}
